.project-list {
    position: absolute;
    background-color: black;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 15px;
    justify-content: flex-end;
    right: 0;
    width: 20vw;
}



.project-list li:nth-child(1) {
    margin-top: 10px;
    color: red;
}

.project-list li {
    margin-bottom: 10px;
    text-align: initial;
    font-style: initial;
    font-weight: 400;
    font-family: 'Work Sans', sans-serif;
    font-size: 0.7vw;
    cursor: pointer;
}

.project-list li:hover,
.nav-item:hover {
    font-weight: 600;
}

.portfolio {
    cursor: default;
    width: 100%;
    text-align: center;
}

.portfolio img {
    width: 3%;
    position: absolute;
    margin: 6px 0 0 5px;
}

@media screen and (max-width: 1000px) {
    .project-list {
        right: initial;
        position: relative;
        z-index: 10000;
        display: none;
        padding: 0;
        margin: 0;
    }

    .project-list li {
        text-align: center;
        font-size: 15px;
        margin-bottom: 0;
    }

    .project-list.active {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .portfolio {
        margin-top: 0.7em;
        font-size: 20px;
    }
}