
.container--clipPage {
    height: 90vh;
    width: 60%;
    margin: 0 auto;
    display: flex;
}





.container--clipPage div:nth-child(1) {
    height: 100%;
    display: flex;
    align-items: center;
}
/* Container YouTube player */


.container--project iframe {
    width: 80%;
    height: 80%;
    border: none;
}

img {
    width: 100%;
    height: auto;
}
h2 {
    text-align: center;
}
span {
    color: white;
}


.container--infoClip {
    align-self: start;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 1em auto;
}

.container--infoClip > p {
    color: white;
    margin: 0;
}
.container--infoClip span {
    color: white;
    margin: 0;
    /* font-size: 0.8vw; */
}

.container--infoClip > p span {
    font-weight: 600;
    /* font-size: 0.8vw; */
}

.container--project{
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
}


@media screen and (max-width: 1000px) {


    .container--project {
        justify-content: initial;
        height: initial;
    }
    .container--project iframe {
        width: 100%;
        height: 55vh;

    }

    .container--infoClip * {
        text-align: center;
        font-size: 4vw;
        width: 100%;
        margin: 1em 0;
        gap: 17px;
    }
}


