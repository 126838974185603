.d-none {
    display: none;
}
.player-wrapper {
   height: 0;
   width: 100%;
   position: relative;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

@media (min-aspect-ratio: 16/9) {
    .player-wrapper {
        padding-bottom: calc(9 / 16 * 100%);
        /* aspect-ratio: 16 / 9; */
    }
}

@media (aspect-ratio: 16/10) {
    .player-wrapper {
        padding-bottom: calc(10 / 16 * 100%);
        transform: scale(1.3);
        transform-origin: center;
        /* scale: 1.25; */
    }
}

@media (aspect-ratio: 4/3) {
    .player-wrapper {
        padding-bottom: calc(3 / 4 * 100%);
        transform: scale(1.45);
        transform-origin: center;
    }
}

@media (aspect-ratio: 21/9) {
    .player-wrapper {
        padding-bottom: calc(9 / 21 * 100%);
        transform: scale(1.45);
        transform-origin: center;
    }
}

@media (aspect-ratio: 32/9) {
    .player-wrapper {
        padding-bottom: calc(9 / 32 * 100%);
        transform: scale(2);
        transform-origin: center;
    }
}