.visible {
    transition: 0.5s;
    opacity: 1;
}

nav ul{
    display: flex;
    justify-content: flex-end;
    list-style: none;
}

.nav-item, .nav-item a {
    text-decoration: none;
    color: white;
    text-transform: uppercase;
}

.nav-item {
    margin: 0 1em;
    font-size: 0.8vw;
}


.d-none {
    display: none;
}

.d-block {
    display: block;
}



li:last-child() {
    margin-bottom: 15px;
}


.active {
    padding: 0.5em;
}



span > span {
    transform: rotate(90deg);
    display: inline-block;
    font-size: 35px;
}

@media screen and (max-width: 1000px) {
    nav {
        display: flex; 
        flex-direction: column;
        width: 100%;
        position: relative;
        text-transform: uppercase;
        align-items: center;
    }

    nav > a {
        margin-top: 10px;
    }

    nav ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;

    }

    nav ul li a {
       all: unset;
       color: white;
       text-transform: uppercase;
    }

    nav a {
        font-size: 20px;
    }

    nav h1 a {
        color: white;
        font-size: 70px;
    }
    
    


    main nav.active {
        display: block;
        width: 100%;
    }

    nav>a {
      color: white;
      text-decoration: none;
    }

}



