div h3 {
    color: white;
}

.container--photo  {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    gap: 1em;
    height: 100vh;
}

.blur-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    filter: brightness(0.6) blur(15px);
    z-index: -1;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    /* transition: background-size 0.7s; */
}



.container--photo .blur-overlay {
    background-size: contain; 
}






.container--photo header {
    position: initial;
    width: 100vw;
}


.container--carousel {
    width: 65%;
    height: 77vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    z-index: 1000;
    z-index: 2;

}

.carousel-item {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    object-position: bottom;
}



.carousel .slider-wrapper {
    width: 85%;
    margin: 0 auto;
}



 .thumbnail-container {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(5, 1fr);
    width: 50%;
    margin-bottom: 2em;
    margin: 0 auto;
    height: 10vh;
    z-index: 2;
}

.thumb {
    border: none!important;
    cursor: pointer;
    margin: 0!important;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    max-height: 40px;
}

ul.thumbs {
    padding: 0;
}

.control-arrow {
    display: none;
}

.thumbs.animated {
    transform: initial!important;
}

.thumbs-wrapper {
    margin: 0!important;
}

.thumb.selected,.thumb:hover {
    opacity: 1;
}

.lazy-load-image-background {
    width: 100%;
}