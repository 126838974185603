

.hero-header {
    position: relative;
    /* overflow-y: hidden; */
    height: 100vh;
}

.hero-header h2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2vw;
    z-index: 1000;
}

.start {
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 2;
}



