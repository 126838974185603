body {
  background-color: black;
  overflow: hidden;
}

h1 {
  margin: 0;
  font-weight: 400;
}

* {
  text-transform: uppercase;
}

@media screen and (max-width: 1000px) {
  body {
    overflow: initial;
  }
}


