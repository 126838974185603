header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 15;
    position: fixed;
}

h1 > a {
    text-decoration: none;
    margin: 0 1em;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5vw;
}

.fixed {
    position: fixed;
}

@media screen and (max-width: 1000px) {
    .desktop_header {
        position: initial;
        display: none;
    }

    .main-title {
        display: none;
    }

    header {
        width: 100%;
        background-color: black;
        position: relative;
        border-bottom: 1px solid white;
    }

    main > header {
        border-bottom: none;
    }


}

