.loading-screen {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    background-color: black;
    z-index: 18;
    top: 0;
}



.loading-screen.hidden {
    background: none;
    transition: 2s;
    z-index: 15;
}

.spinner {
    display: block!important;
}

.loading-screen *:not(div>span, br) {
    opacity: 0;
    transition: 1s;
    color: white;
    padding: 0 0.5em;
}


.loading-screen h1, .loading-screen h2, .loading-screen span {
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
}
.loading-screen h2 {
    font-size: 1.2vw;
}



.loading-screen h1.active, .loading-screen h2.active, .loading-screen span.active {
    opacity: 1;
    transition: ease-in-out 1s;
}

.d-none {
    opacity: 0;
    transition: 1s;
}


@media screen and (max-width: 1000px) {
    main {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    img {
        margin: 0;
    }

    svg {
        z-index: 10000;
    }

    .scroll-to-top {
        background-color: black!important;
        right: 20px!important;
    }

    .scroll-to-top svg {
        color: white;
    }
}
