.container--about {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 50%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute;
    gap: 5em;
}
.container--about div {
    flex: 1;
    font-size: 1vw;
    widows: 2;
    orphans: 2
}
.container--about > span > img {
    height: auto;
    width: 100%;
}
.bio {
    text-transform: initial;
}
.container--about .lazy-load-image-background {
    width: 40%;
}

.container--about p {
    color: white;
    font-weight: 500;
}

/* .container--about h3 {
    text-align: center;
} */

.heading-about span:nth-child(2), .heading-about span:nth-child(4) {
    cursor: pointer;
}

.openToWork {
    text-transform: initial;
}

.openToWork > span   {
    transform: initial;
    font-size: 0.7vw;
}

@media screen and (max-width: 1000px) {
    .container--about {
        all: initial;
        font-family: 'Work Sans';
        font-size: 13px;
        margin-bottom: 1em;
    }

    .container--about .lazy-load-image-background {
        width: 100%;
        text-align: center;
    }

    .heading-about {
        text-align: center;
        font-size: 12px!important;
    }

    .container--about > span > img {
        width: 50%;
    }

    .container--about > span {
        display: inline-block;
        margin-top: 20px;
    }

    .container--about p {
        width: 80%;
        margin: 20px auto;
        font-size: 15px;
        text-transform: initial;
    }
    .openToWork {
        text-align: center;
        width: 80%;
        display: block;
        margin: 0 auto;
        text-transform: initial;
        font-size: initial;
        padding: 1em 0;
    }

    .openToWork span {
        font-size: initial;
    }
}
